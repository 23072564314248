import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Gondwana-15 conference will take place in Madrid from 14 to 18 July. Colin
Reeves will be  presenting two papers, one reporting recent work on the precise
process by which Gondwana  disrupted, the other appealing for a new cooperation
between geologists and geophysicists in  making a better map of the Precambrian
crust of Africa as a constituent element of the new  Geological Map of Gondwana
(IGCP 628). `}</p>
    <p>{`The first talk has the title `}<strong parentName="p">{`A Brief History of the Oceans that split Gondwana`}</strong>{`,
the second `}<strong parentName="p">{`An appeal for a map of Precambrian Gondwana with input from regional
geophysical programmes in Africa and elsewhere`}</strong>{`. Anyone interested in
contributing data or ideas to this  second objective is invited to contact Colin
directly. The picture below gives an impression of the  level of detail in the
latest animation of Gondwana dispersal. The ocean data is based in part on  the
CGMW Geological Map of the World (2010) with refinements filling the gaps from
the latest  dispersal model. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      